// COLORS
$white: #fff;
$black: #000000;
$grey: #e5e5e5;
$dark-grey: #a6b5bc;
$red: #dc3545;

$primary-light: #95d5b2;
$primary: #52b788;
$primary-dark: #2d6a4f;
$secondary-light: #e9964d;
$grey-light: #f3f6f7;
$grey: #edf0fa;
$grey-dark: #d4dbe0;
$green: #11a965;
$blue: #4a69c5;

// input border
$blue-light: #52b788;
$light-teal: rgba(166, 181, 188, 0.4);

// BREAKPOINTS
$xs: 479px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

// FONT
$noe-display: "Noe Display Bold";
$open-sans: "Open Sans Regular";
$arial: "Arial Regular";

// TYPOGRAPHY
$normal: 400;
$bold: 600;
$font-xs: 12px;
$font-sm: 14px;
$font-md: 18px;
$font-lg: 24px;
$font-xl: 60px;
