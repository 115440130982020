// Background colors for elements
.haloo {
  .bg {
    &-primary-light {
      background-color: $primary-light;
    }
    &-secondary-light {
      background-color: $secondary-light;
    }
    &-grey-light {
      background-color: $grey-light;
    }
  }
}
