.haloo {
  .prescreening {
    .logo {
      height: 100px;
      padding-right: 10px;
    }

    .error {
      color: $red;
    }

    .card {
      text-align: left;
      background-color: $white;
      border: 1px solid $grey-dark;
      box-shadow: 0px 0px 16px -4px rgba(0, 0, 0, 0.14);
      padding: 20px;
      margin: 20px 0;
      width: 100%;
      & > div > p > strong {
        word-break: break-all;
      }
      &-title {
        font-size: 1.2em;
      }

      .expert {
        padding-left: 20px;
        background-image: url(../../assets/icons/search_item_star.svg);
        background-repeat: no-repeat;
        background-size: 15px 15px;
        background-position: left center;
      }
      mark {
        background-color: transparent;
        color: $green;
        font-weight: bold;
      }
      .thead {
        padding: 10px 0;
        margin: 0 2px;
      }
      .tr {
        border-top: 2px solid $grey;
        padding: 10px 0;
        margin: 0 2px;

        @media screen and (max-width: $lg) {
          mark {
            display: none;
          }
        }
        @media screen and (max-width: $sm) {
          text-align: center;
          .circle {
            margin: auto;
          }
        }
      }
      &-unavailable {
        color: $dark-grey;
      }

      &-social {
        & > div {
          display: grid;
          grid-template-columns: 20% auto;
          & > div {
            display: grid;
            grid-template-columns: auto 30%;
            padding: 10px 0;
            img {
              margin: auto;
            }
            p:first-child {
              text-align: left;
            }
          }
          & > div:last-child {
            border-bottom: 1px solid $grey;
          }
          @media (max-width: $md) {
            display: block;
            text-align: center;
            & > div {
              display: block;
              padding: 0;
              strong {
                display: block;
              }
              p:first-child {
                text-align: center;
              }
            }
            & > div:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }

    &-card {
      border: 1px solid $grey-dark;
      cursor: pointer;
      background-color: $white;
      color: $black;
      padding: 31px;
      margin: 10px 0;
      position: relative;
      top: 0px;
      transition: top ease 0.3s;
      height: 300px;
      @media screen and (max-width: $lg) {
        height: 350px;
      }
      @media screen and (max-width: $md) {
        height: auto;
      }
      &:hover,
      &:active,
      &:focus {
        color: $white;
        background-color: $black;
        opacity: 0.75;
        outline: 0;
        h2 {
          color: $white;
        }
      }
      p {
        font-size: 15px;
        line-height: 25px;
      }
    }

    &-preview-results {
      background-color: $white;
      ul {
        list-style: disc;
        font-size: 14px;
        line-height: 18px;
      }
      .container {
        padding: 50px 0;
      }
      &-logo {
        align-items: center;
        margin: 0;
        .col-md-8 {
          padding-left: 50px;
        }
        &-container {
          background-color: white;
          text-align: center;
          padding: 30px 0;
          img {
            width: 50%;
          }
        }
      }
    }
    &-logo {
      ::-webkit-file-upload-button {
        display: none;
      }
      &-preview {
        @media (min-width: $xs) {
          border-radius: 5px;
        }
        @media (min-width: $lg) {
          border-radius: 0 5px 5px 0;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 80%;
          max-height: 300px;
        }
        .pdf-icon {
          max-height: 150px;
        }
      }
      &-upload {
        min-height: 300px;
        img {
          max-width: 80%;
          max-height: 200px;
        }

        > div:first-child {
          align-self: center;
        }
        img#upload-icon {
          border-radius: 50%;
          border: 3px dotted transparent;
        }
        img#upload-icon.hovered {
          border: 3px dotted $primary;
          border-radius: 50%;
        }
      }
      &-file {
        position: relative;
        input[type="file"] {
          background-color: transparent;
          position: absolute;
          z-index: 10;
          color: rgba(0, 0, 0, 0);
          border: none;
          &:focus-visible {
            border: 2px solid rgb(13 110 253 / 25%);
          }
        }

        input[disabled] {
          cursor: default;
        }
      }
    }

    .jurisdiction {
      .input {
        padding: 0;
        cursor: pointer;
      }
      input {
        float: right;
        margin: 17px;
      }
      .form-check {
        height: 100%;
        &-input:checked {
          background-color: green;
          // border-color: green;
        }
        &-input:focus {
          box-shadow: none;
        }
      }
      .form-control {
        color: rgb(111, 109, 109);
        &.checked {
          background-color: $primary-light;
          color: $black;
        }
      }

      label:nth-child(n + 2) {
        padding: 17px;
        display: block;
        cursor: pointer;
        height: 100%;
      }
    }
  }
}
