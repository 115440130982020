.haloo {
  .form-label {
    margin-bottom: 0;
    margin-top: 0.5rem;
    font-family: $arial;
  }

  .form-control {
    &:focus {
      box-shadow: none;
    }
  }

  .input {
    height: 60px;
    border: 2px solid $grey-dark;
    font-family: $arial;
    padding: 17px;
    font-size: 15px;
    display: block;
    margin: 10px auto;
    width: 100%;
    color: $black;
    border-radius: 0px;

    &:focus-visible {
      outline: $blue-light;
      border: 2px solid $blue-light;
      border-radius: 0px;
    }

    &-container {
      display: grid;
      grid-template-columns: 0 auto 0;
      grid-template-areas: ". input required";
      @media (max-width: $md) {
        display: block;
      }
      .input.input[type="text"],
      .input.input[type="email"] {
        grid-area: input;
      }
      .input-error {
        border: 1px solid $red;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      }
    }
  }

  .input-error,
  .input-error:focus {
    border: 1px solid $red;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}
