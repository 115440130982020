.haloo {
  .typeahead {
    grid-area: input;
    position: relative;

    input {
      &:focus {
        &.input {
          border-radius: 4px 4px 0 0;
        }
      }
    }

    ul {
      font-family: $arial;
      text-align: left;
      border: 2px solid $primary-dark;
      border-radius: 0 0 4px 4px;
      background-color: $white;
      box-shadow: 2px 2px 2px var(--shadow);
      max-height: 250px;
      overflow: scroll;
      z-index: 1;
      margin-top: -10px;
      position: absolute;
      width: 100%;
    }
    &-tooltip {
      visibility: hidden;
      &.error {
        visibility: visible;
        color: $red;
      }
    }
    &-highlight {
      font-weight: 700;
    }
    &-option {
      cursor: pointer;
      padding: 10px 5px;
      &-disabled {
        padding: 10px 5px;
        color: $grey;
        cursor: default;
      }
      &:hover,
      &-active {
        // background-color: $black;
        background-color: $primary-dark;
        color: $white;
      }
    }
    &-search {
      display: flex;
      width: 50%;
      button {
        margin-top: 10px;
        margin-left: 10px;
      }
    }
    &-selection {
      display: flex;
      margin: 5px 0;

      &-text {
        &:focus-visible {
          border: 1px solid $black;
          background-color: $white;
          color: $black;
        }
        font-family: $arial;
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        width: 100%;
        background-color: $primary-dark;
        color: $white;
        border-radius: 4px;
        svg {
          font-size: 1.2em;
          cursor: pointer;
        }
      }
    }
  }
}
