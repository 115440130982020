// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
  font-family: "Noe Display Bold";
  font-style: bold;
  src: url("../../assets/fonts/NoeDisplay-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans Regular";
  font-style: normal;
  src: url("../../assets/fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Arial Regular";
  font-style: normal;
  src: url("../../assets/fonts/Arial-Regular.ttf") format("truetype");
}
