/**
 * Basic typography style for copy text
 */
.haloo {
  body {
    font-size: 18px;
    line-height: 23.4px;
  }
  h1 {
    font-family: $noe-display !important;
    font-size: 60px;
    line-height: 65px;
    color: $black;
    letter-spacing: -0.01em;
  }
  h2 {
    font-family: $noe-display;
    font-size: 45px;
    line-height: 57px;
    color: $black;
  }
  h3 {
    font-family: $noe-display;
    font-size: 28px;
    line-height: 35px;
    color: $black;
  }
  h4,
  h5,
  h6 {
    font-family: $noe-display;
    font-size: 18px;
    line-height: 23px;
    color: $black;
  }
  a {
    font-family: $open-sans;
    font-size: 18px;
    line-height: 26px;
    text-decoration: underline;
    color: $black;
    &.no-underline {
      text-decoration: none;
    }
    &.underline {
      text-decoration: underline;
    }
    &:hover {
      color: $black;
    }
  }

  button {
    font-family: $open-sans;
    font-size: 18px;
    line-height: 18px;
  }

  @media (max-width: $md) {
    body {
      font-size: 16px;
    }
    h1 {
      font-size: 36px;
      line-height: 45px;
    }
    h2,
    h3 {
      font-size: 21px;
    }
  }

  .font-sansserif {
    font-family: $open-sans !important;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      font-family: $open-sans !important;
    }
  }

  .font-sansserif.bold {
    font-family: $open-sans !important;
  }

  .font-serif {
    font-family: $noe-display;
  }
  .font-sanserif {
    font-family: $arial;
  }

  .text-white {
    color: $white;
  }

  p.note {
    font-size: 13px;
  }
}
