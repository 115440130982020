.haloo {
  .progress-bar.indeterminate {
    position: relative;
    animation: progress-indeterminate 7.5s linear infinite;
  }

  @keyframes progress-indeterminate {
    from {
      left: -25%;
      width: 25%;
    }
    to {
      left: 100%;
      width: 25%;
    }
  }

  .searching {
    .progress {
      height: 20px;
      border-radius: 2rem;
      background-color: $grey-light;
      .progress-bar {
        background-color: $primary-light;
        border-radius: 2rem;

        &.prelim-step-1 {
          width: 17%;
        }
        &.prelim-step-2 {
          width: 50%;
        }
        &.prelim-step-3 {
          width: 85%;
        }

        @media (max-width: $lg) {
          &.prelim-step-1,
          &.prelim-step-2,
          &.prelim-step-3,
          &.prelim-step-4 {
            width: 50%;
          }
        }
      }
    }
    .indicators > div {
      text-align: center;
      width: calc(100% / 3);
      padding: 0 20px;
      @media (max-width: $lg) {
        width: auto;
      }
      > span {
        font-size: 11px;
        position: relative;
        bottom: 9px;
      }
      > p {
        &.index {
          font-size: 1em;
        }
        &.label {
          font-size: 0.8em;
          line-height: 1;
          padding-top: 10px;
        }
      }
    }
    .grow {
      animation: fadeIn 0.75s linear 1 forwards;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
        transform: scale(0.5);
      }
      25% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}
