.haloo {
  .partner-integration {
    > .container,
    > .container-fluid {
      @media screen and (min-width: $xs) {
        padding: 2rem 0;
      }
      @media screen and (min-width: $md) {
        padding: 4.5rem 0;
      }
    }

    .progress {
      background-color: $white;
      border: 2px solid $blue;
      height: 1.5rem;
      border-radius: 0;
      .progress-bar {
        background-color: $grey;
        border-right: 2px solid $blue;
      }
    }
    .progress-bar-labels {
      bottom: 10px;
      span {
        font-size: 12px;
        font-weight: $bold;
        color: $blue;
      }
    }
  }
}
