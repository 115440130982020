.haloo {
  .btn {
    height: 66px;
    border: 1px solid transparent;
    text-align: center;
    border-radius: 0;
    width: auto;
    font-weight: 700;

    &-link {
      color: $black;
      &:hover {
        color: $black;
      }
    }

    &-primary,
    &-primary:hover:not([disabled]) {
      background-color: $black;
      color: $white;

      transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      -ms-transform: scale(1);
      transition: scale(1) 0.3s ease-in-out;
      -webkit-transition: transform 0.3s ease-in-out;
      -moz-transition: transform 0.3s ease-in-out;
      -ms-transition: transform 0.3s ease-in-out;
      -o-transition: transform 0.3s ease-in-out;
      &:focus,
      &:active {
        background-color: $black;
        color: $white;
        transform: scale(0.99);
      }
      &:hover {
        transform: scale(1.02);
        -moz-transform: scale(1.02);
        -webkit-transform: scale(1.02);
        -o-transform: scale(1.02);
        -ms-transform: scale(1.02);

        transition: scale(1.02) 0.3s ease-in-out;
        -webkit-transition: transform 0.3s ease-in-out;
        -moz-transition: transform 0.3s ease-in-out;
        -ms-transition: transform 0.3s ease-in-out;
        -o-transition: transform 0.3s ease-in-out;
      }
    }
    &-outline,
    &-outline:hover:not([disabled]) {
      background-color: $white;
      color: $black;
      border: 2px solid $black;
      &:active,
      :focus-visible {
        opacity: 0.75;
        transform: scale(0.99);
      }
    }

    &-secondary {
      background-color: $black;
      color: $white;
      &:hover:not([disabled]),
      &:focus {
        background-color: $white;
        border: 1px solid $black;
        color: $black;
      }
    }

    &-toggle {
      display: inline-grid;
      justify-items: center;
      background-color: $white;
      color: $black;
      &:hover:not([disabled]),
      &:focus {
      }
    }
  }
}
