body {
  font-family: $arial, sans-serif;
  color: $black;
  font-size: 18px;
  line-height: 23.4px;
  font-weight: normal;
  scroll-behavior: smooth;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  padding: 0 !important;
}

h1,
h2,
h3 {
  font-family: $noe-display, sans-serif;
  font: inherit;
}

h4,
h5,
h6 {
  font: inherit;
  font-weight: normal;
}

a,
button {
  font-family: $open-sans, sans-serif;
  font-weight: normal;
  cursor: pointer;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* http://www.paulirish.com/2012/box-sizing-border-box-ftw/ (2015/04/28)*/
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
/* Additional resets */
a {
  color: inherit;
}
button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  text-align: inherit;
  outline: none;
  font: inherit;
  line-height: inherit;
  -webkit-appearance: none;
}
/* Fix antialiasing */
*,
*:before,
*:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Disable user select on everything but texts */
*,
*:before,
*:after {
  user-select: none;
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
ul,
ol,
li,
table,
tr,
th,
td,
input,
textarea {
  user-select: text;
}
strong,
b,
a,
u,
sup,
i {
  user-select: initial;
}
